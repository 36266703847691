<template>
  <div>
    <this-header />

    <div class="container-new pb-5">
      <div class="d-flex align-items-center">
        <router-link
          v-if="UserData.role
            == 'member'"
          :to="{ name: 'tanghuay-home' }"
        >
          <button
            class="bnt-home"
          >
            <i class="fas fa-home" />
          </button>
        </router-link>
        <div class="txt-right d-flex justify-content-between">
          <span class="txt-right__text">โพยหวย</span>
        </div>
      </div>

      <div class="mb-2 mt-1">
        <router-link
          :to="{ name: 'tanghuay-list' }"
        >
          <span class="text-white mt-2"> <i class="far fa-arrow-circle-left" /> ย้อนกลับ</span>
        </router-link>
        <div class="header-bg-content2 text-center">
          <h3 class="text-white font-medium-1">
            เลขที่ {{ $route.params.id }}
          </h3>
          <b-row>
            <b-col cols="6">
              <div>
                <p style="color: #fff">
                  ราคาที่แทงรวม
                </p>
                <p style="color: #fff; font-weight: 600; font-size: 20px;">
                  {{
                    Commas(
                      ListDataHead.reduce(
                        (acc, ele) => acc + Number(ele.amount),
                        0,
                      ),
                    )
                  }}
                  ฿
                </p>
              </div>
            </b-col>
            <b-col cols="6">
              <div>
                <p style="color: #fff">
                  ผลชนะทั้งสิ้น
                </p>
                <p style="color: #fff; font-weight: 600; font-size: 20px;">
                  {{
                    ListDataHead.some(
                      el =>
                        el.status_result !== 'lose' && el.status_result !== 'win',
                    )
                      ? '-'
                      : Commas(
                        ListDataHead.reduce(
                          (acc, ele) =>
                            acc +
                            (ele.status_result === 'win'
                              ? Number(ele.amount) * Number(ele.win_rate)
                              : 0),
                          0,
                        )
                      )
                  }}
                  ฿
                </p>
              </div>
            </b-col>
            <b-col
              v-if="
                ListDataCal.some(
                  el => el.status_result === 'waiting' && el.status !== 'cancle',
                )
              "
              cols="12"
            >
              <button
                v-if="MinuteRefund <= 15"
                variant="warning"
                block
                class="btn btn-block"
                style="background: linear-gradient(to right, #BF953F, #FCF6BA, #B38728); color: #000 !important; font-weight: 700;"
                @click="CancelBill($route.params.id)"
              >
                คืนโพยหวย
              </button>
            </b-col>
          </b-row>
        </div>
      </div>

      <div>
        <b-overlay
          :show="show"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <b-icon-controller
                font-scale="3"
                animation="cylon"
              />
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>

          <div
            v-if="!show"
            class="section-wrap"
          >
            <div
              v-for="(key,index) in ListData[0]"
              :key="key.index"
            >
              <div
                class="section-title-l"
              >
                <strong class="font-weight-bolder"> {{ index }} </strong>
              </div>
              <ul
                v-for="item in key"
                :key="item._id"
                class="list-group"
              >
                <li
                  class="row-group align-items-center"
                  style="height: initial;"
                >
                  <div class="box-number">
                    <strong class="color-green"> {{ item.bet }}</strong>
                  </div>
                  <div class="box-text-price h-space">แทง

                    <br> จ่าย

                  </div>
                  <div class="box-price">
                    <strong> {{ Commas(item.amount) }} </strong>

                    <br>
                    {{ Commas(item.win_rate) }}

                  </div>
                  <div class="box-result d-flex justify-content-between color-red">
                    <div class="rs-text-left">

                      <span
                        style="font-size:100%"
                        :style="`color: ${
                          item.status_result === 'win'
                            ? 'blue'
                            : item.status_result === 'lose'
                              ? 'red'
                              : item.status === 'cancle'
                                ? 'red'
                                : 'black'
                        }`"
                      >
                        {{
                          item.status_result === 'win'
                            ? 'ชนะ'
                            : item.status_result === 'lose'
                              ? 'แพ้'
                              : item.status === 'cancle'
                                ? 'ยกเลิกโพย'
                                : 'รับแทง'
                        }}
                      </span>
                    </div>
                    <div class="re-text-right">
                      <span
                        v-if="item.status === 'confirm'"
                        style="font-size:100%"
                        :style="`color: ${
                          item.status_result === 'win'
                            ? 'blue'
                            : item.status_result === 'lose'
                              ? 'red'
                              : 'black'
                        }`"
                      >                       {{
                        item.status_result === 'win' ||
                          item.status_result === 'lose'
                          ? Commas(
                            (item.status_result === 'win'
                              ? item.amount * item.win_rate
                              : '-' + (item.amount)),
                          )
                          : 'รอผล'
                      }}</span>
                      <span
                        v-else
                        style="font-size:100%"
                        :style="`color: red`"
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          <!-- End Loop -->
          </div>
        </b-overlay>
      </div>
    </div>

    <this-footer />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  // BCard,
  // BTable,
  // BFormSelect,
  BOverlay,
  BIconController,
} from 'bootstrap-vue'
import momenttz from 'moment-timezone'
import ThisHeader from './component/ThisHeader.vue'
import ThisFooter from './component/ThisFooter.vue'

export default {
  components: {
    BRow,
    BCol,
    // BCard,
    // BTable,
    // BFormSelect,
    BOverlay,
    BIconController,
    ThisHeader,
    ThisFooter,
  },
  data() {
    return {
      UserData: JSON.parse(localStorage.getItem('userData')),
      name_type: null,
      op_type: [
        { value: null, text: 'ทั้งหมด' },
        { value: '3ตัวบน', text: '3 ตัวบน' },
        { value: '3ตัวล่าง', text: '3 ตัวล่าง' },
        { value: '3ตัวโต๊ด', text: '3 ตัวโต๊ด' },
        { value: '2ตัวบน', text: '2 ตัวบน' },
        { value: '2ตัวล่าง', text: '2 ตัวล่าง' },
        { value: '2ตัวโต๊ด(บน)', text: '2 โต๊ด(บน)' },
        { value: 'วิ่งบน', text: 'วิ่งบน' },
        { value: 'วิ่งล่าง', text: 'วิ่งล่าง' },
      ],
      status_result: null,
      op_reward: [
        { value: null, text: 'ทั้งหมด' },
        { value: 'waiting', text: 'รอผล' },
        { value: 'win', text: 'ถูกรางวัล' },
        { value: 'lose', text: 'ไม่ถูกรางวัล' },
      ],
      fields: [
        {
          key: 'no',
          label: '#',
        },
        {
          key: 'bet',
          label: 'เลข',
        },
        {
          key: 'name_type',
          label: 'ประเภท',
        },
        {
          key: 'amount',
          label: 'ยอดแทง',
          formatter: value => this.Commas(value),
        },
        {
          key: 'win_rate',
          label: 'จ่าย',
          formatter: value => this.Commas(value),
        },
        {
          key: 'result_bet',
          label: 'เลขที่ออก',
        },
        {
          key: 'win_lose',
          label: 'ผลได้เสีย',
        },
        {
          key: 'status_result',
          label: 'สถานะ',
          // variant: 'success',
        },
      ],
      items: [
        {
          no: '1',
          bet: '50',
          status_result: '999',
        },
      ],
      ListDataHead: [],
      ListData: [],
      ListDataCal: [],
      Result: [],
      Type: null,
      MinuteRefund: 0,
      Interval: null,
      show: false,
      usernameshow: null,
      numdup: 0,
    }
  },
  beforeDestroy() {
    clearInterval(this.Interval)
  },
  mounted() {
    this.ShowHistory()
  },
  methods: {
    rowClass(item, type) {
      const colorClass = 'table-success'
      if (!item || type !== 'row') {
        return
      }
      if (item.status_result === 'win') {
        // eslint-disable-next-line consistent-return
        return colorClass
      }
    },
    async ShowHistory() {
      this.show = true
      const params = {
        ListId: this.$route.params.id,
        status_result: this.status_result,
        name_type: this.name_type,
      }
      try {
        const { data: response } = await this.$http.get(
          'https://api.rmtlotto.com/api/member/history/ShowByListId',
          { params },
        )
        this.show = false
        if (response && response.success === true) {
          this.ListDataCal = response.Mes
          this.CheckDup(response.Mes)
          if (response.Mes[0].round_id) {
            this.usernameshow = response.Mes[0].username
            this.GetResultThai(response.Mes[0].round_id)
          }
          if (response.Mes[0].LottoHeadName === 'หวยรัฐบาล') {
            this.Type = 0
          } else {
            this.Type = 1
          }
          const group = await response.Mes.reduce((r, a) => {
            // eslint-disable-next-line no-param-reassign
            r[a.name_type] = [...r[a.name_type] || [], a]
            return r
          }, {})
          this.ListData.push(JSON.parse(JSON.stringify(group)))
          // eslint-disable-next-line prefer-destructuring
          if (this.ListDataHead.length === 0) {
            this.ListDataHead = response.Mes
          }
          if (this.ListDataCal.length > 0) {
            this.MinuteRefund = this.DiffMinute(this.ListDataCal[0].created_at)
            this.Interval = setInterval(() => {
              this.MinuteRefund = this.DiffMinute(this.ListDataCal[0].created_at)
            }, 60000)
          }
          // console.log(this.MinuteRefund)
        }
      } catch (err) {
        console.log(err)
      }
    },
    CheckDup(data) {
      const Array = []
      data.forEach(items => {
        // eslint-disable-next-line no-unused-expressions
        if (Array.indexOf(items.bet) === -1) {
          Array.push(items.bet)
        }
      })
      this.numdup = Array.length
    },
    async GetResultThai(id) {
      const params = {
        id,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.rmtlotto.com/api/member/result/ByRoundID',
          { params },
        )

        if (ResData.success) {
          this.Result = ResData.mes
        }
      } catch (e) {
        console.log(e)
      }
    },
    DiffMinute(DateTime) {
      const DateList = momenttz(DateTime).tz('Asia/Bangkok')
      const DateNow = momenttz().tz('Asia/Bangkok')
      const Minutes = DateNow.diff(DateList, 'minutes')
      if (Minutes > 15) {
        clearInterval(this.Interval)
      }
      return Minutes
    },
    CancelBill(ListID) {
      this.$swal({
        title: 'แจ้งเตือน ?',
        text: 'ยืนยันที่จะคืนโพยของคุณหรือไม่ ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.show = true
          const Obj = {
            // eslint-disable-next-line no-underscore-dangle
            ListID,
            UserToken: localStorage.getItem('UserToken'),
          }
          this.$http
            .post(
              'https://api.rmtlotto.com/api/seamless/cancle', Obj,
            )
            .then(response => {
              if (response.data) {
                this.ListDataHead = []
                this.ListData = []
                this.ListDataCal = []
                this.Result = []
                this.ShowHistory()
                this.show = false
                this.$swal({
                  icon: 'success',
                  title: 'สำเร็จ',
                  text: 'คืนโพยสำเร็จ',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
            .catch(error => {
              console.log(error)
              this.show = false
              this.$swal({
                icon: 'error',
                title: 'ไม่สำเร็จ',
                text: error.response.data.Mes,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        } else {
          this.show = false
          this.$swal('ยกเลิก', 'ยกเลิกการคืนโพย !', 'error')
        }
      })
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    SwalMes(Type, Title, Text) {
      this.$swal({
        icon: Type,
        title: Title,
        text: Text,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.sub-text {
  font-size: 0.8rem;
  color: rgb(128, 128, 128);
}
.bd_list {
  padding: 10px;
  border-radius: 0px 0px 5px 5px;
  background-color: rgb(255, 255, 255);
}
.hd_list {
  padding: 10px;
  border-radius: 5px 5px 0px 0px;
  background-color: rgb(206, 206, 206);
  border-bottom: 1px solid rgb(160, 160, 160);
}
.header-bg-content2 {
  /* background-image: url(/images/bg-header.svg); */
  background-image: url(/back_bg.svg);
  background-size: 50px 50px;
  background-repeat: repeat;
  background-color: #031d72;
  padding: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.219);
  border-radius: 10px;
}
.btn-type3 {
  width: 100%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 2px;
  border-radius: 25px;
  font-weight: 700;
  border: 0px;
}
.btn-type2 {
  width: 100%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 2px;
  font-weight: 700;
  border-radius: 25px;
  border: 0px;
}
.btnp:hover {
  background-color: #fff;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  background-size: 200% auto;
  transition: 0.5s;
}

.active,
.btnp:focus {
  background-color: #fff;
  color: rgb(0, 0, 0);
}
.pander {
  font-size: 0.8rem;
  padding-right: 5px;
  padding-left: 5px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  background-color: rgb(179, 0, 0);
  border-radius: 5px;
}
.bn {
  color: #000 !important;
  font-weight: 600;
}
</style>
